<template>
    <o-data-lookup :data-object="dsDocuments">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                <slot name="document"></slot>
            </span>
        </template>
        <o-column field="ID" width="100"></o-column>
        <o-column field="OrgUnit" width="100"></o-column>
        <o-column field="Name" width="100"></o-column>
        <o-column field="Title" width="350"></o-column>
        <o-column field="Rev" width="100"></o-column>
        <o-column field="DocumentTypeAndTitle" width="200"></o-column>
        <o-column field="OrgUnitWithParent" width="200"></o-column>
        <o-column field="Status" width="200"></o-column>

        <!-- Mobile custom -->
        <template #mobileItem="{row}">
            <MListItem :row="row">
                <div class="d-flex justify-content-between">
                    <div class="font-n3 uppercase tracking-4 text-muted align-self-end">
                        {{ row.DocumentTypeAndTitle }}
                    </div>

                    <div class="d-flex">
                        <div class="font-n3 tracking-4 text-muted align-self-end">
                            {{ row.Extension }}&nbsp;
                        </div>

                        <div class="d-flex align-items-end" style="font-size: 2em;">
                            <i v-if="row.Extension=='pdf'" style="color: red;" class="far fa-file-pdf"></i>
                            <i v-if="row.Extension=='doc' || row.Extension=='docx'" style="color: blue;" class="far fa-file-word"></i>
                            <i v-if="row.Extension=='html'" class="far fa-file-code"></i>
                            <i v-if="row.Extension=='xls' || row.Extension=='xlsx'" style="color: green;" class="far fa-file-excel"></i>
                            <i v-if="row.Extension=='ppt' || row.Extension=='pptx'" style="color: orange;" class="far fa-file-powerpoint"></i>
                        </div>
                    </div>
                </div>

                <div class="fw-bold">
                    {{ row.Title }}
                </div>


                <div class="d-flex" style="">
                    <MPill size="sm" :color="getStatusColor(row.Status)" uppercase>{{ row.Status }}</MPill>
                </div>
                
                <div>
                    <span class="fw-medium">Doc. No. / Name:</span> {{ row.Name }}
                </div>

                <div>
                    <span class="fw-medium">Org Unit:</span> {{ row.OrgUnit }}
                </div>

                <div class="text-end font-n3 uppercase tracking-4 text-muted" v-if="row.Rev">
                    Rev: {{ row.Rev }}
                </div>
            </MListItem>
        </template>
    </o-data-lookup>
</template>

<script setup>
import { getOrCreateDataObject } from 'o365.vue.ts'
import MListItem from "mobile.vue.components.ListItem.vue";
import MPill from "mobile.vue.components.Pill.vue";
import { computed, onMounted, watch } from 'vue';

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,
    whereClause: String,
});

const whereClause = computed(() => props.whereClause);

watch(whereClause, () => {
    dsDocuments.recordSource.whereClause = whereClause.value;
    dsDocuments.load();
});

const dsDocuments = getOrCreateDataObject({
    id: 'dsDocuments' + crypto.randomUUID(),
    viewName: 'aviw_Arena_Documents',
    maxRecords: 25,
    whereClause: whereClause.value ? whereClause.value : '',
    loadRecents: false,
    distinctRows: true,
    fields: [
        { name: "ID", type: "string" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "OrgUnit", type: "string" },
        { name: "Status", type: "string" },
        { name: "Status_ID", type: "int"},
        { name: "Rev", type: "string" },
        { name: "DocumentTypeAndTitle", type: "string" },
        { name: "OrgUnitWithParent", type: "string" },
        { name: "Extension", type: "string" }
    ]
});

const statusColors = {
    "danger": [ "Not Approved", "Required Fields Missing", "Check Not Approved" ],
    "dark": [ "Draft" ],
    "info": [ "Received" ],
    "light": [ "Planned" ],
    "primary": [ "New Revision", "Created", "Draft Revision" ],
    "secondary": [ "Obsolete", "Deleted" ],
    "success": [ "Checked", "Released", "Review Completed", "Approved", "IDC Completed", "3rd Party Review Completed" ],
    "warning": [ "Review Ongoing", "To Be Checked", "To Be Approved", "To Be Revised", "To Be Verified", "To Be Delivered", "IDC in Progress", "3rd Party Review Ongoing", "Released | New Revision Being Prepared" ],
}

const getStatusColor = (status) => {
    for (const color in statusColors) {
        if (statusColors[color].includes(status)) {
            console.log(color);
            return color;
        }
    }
    return "primary";
};
</script>
